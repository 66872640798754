var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "text-xs-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "offset-sm3": "", sm6: "", xs12: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { depressed: "", tile: "" },
                  on: { click: _vm.test1 },
                },
                [_vm._v(" test 1 ")]
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _c("span", { staticClass: "subheading" }, [
                          _vm._v("Blinker speed"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-4",
                          attrs: { justify: "space-between" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-left" },
                            [
                              _c("span", {
                                staticClass: "text-h2 font-weight-light",
                                domProps: { textContent: _vm._s(_vm.pos[0]) },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "subheading font-weight-light mr-1",
                                },
                                [_vm._v("BPM")]
                              ),
                              _c(
                                "v-fade-transition",
                                [
                                  _vm.isOn
                                    ? _c("v-avatar", {
                                        staticClass: "mb-1 v-avatar--metronome",
                                        style: {
                                          animationDuration:
                                            _vm.animationDuration,
                                        },
                                        attrs: { color: "red", size: "12" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "#005db5",
                                    dark: "",
                                    depressed: "",
                                    fab: "",
                                  },
                                  on: { click: _vm.toggle },
                                },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isOn ? "pause" : "play_arrow"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-slider", {
                        attrs: {
                          "always-dirty": "",
                          color: "#005db5",
                          max: "600",
                          min: "10",
                          "track-color": "grey",
                        },
                        on: { mouseup: _vm.post_duration },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.decrement(0)
                                      },
                                    },
                                  },
                                  [_vm._v(" remove ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.increment(0)
                                      },
                                    },
                                  },
                                  [_vm._v(" add ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.pos[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.pos, 0, $$v)
                          },
                          expression: "pos[0]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _c("span", { staticClass: "subheading" }, [
                          _vm._v("Rheostat One"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-4",
                          attrs: { justify: "space-between" },
                        },
                        [
                          _c("v-col", { staticClass: "text-left" }, [
                            _c("span", {
                              staticClass: "text-h3 font-weight-light",
                              domProps: { textContent: _vm._s(_vm.pos[1]) },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "subheading font-weight-light mr-1",
                              },
                              [_vm._v("pos[1]")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-slider", {
                        attrs: {
                          "always-dirty": "",
                          color: "#005db5",
                          max: "1024",
                          min: "0",
                          "track-color": "grey",
                        },
                        on: { mouseup: _vm.post_duration },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.decrement(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" remove ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.increment(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" add ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.pos[1],
                          callback: function ($$v) {
                            _vm.$set(_vm.pos, 1, $$v)
                          },
                          expression: "pos[1]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _c("span", { staticClass: "subheading" }, [
                          _vm._v("Rheostat Two"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mb-4",
                          attrs: { justify: "space-between" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-left" },
                            [
                              _c("span", {
                                staticClass: "text-h3 font-weight-light",
                                domProps: { textContent: _vm._s(_vm.pos[2]) },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "subheading font-weight-light mr-1",
                                },
                                [_vm._v("pos[2]")]
                              ),
                              _c(
                                "v-fade-transition",
                                [
                                  _vm.isOn
                                    ? _c("v-avatar", {
                                        staticClass: "mb-1 v-avatar--metronome",
                                        style: {
                                          animationDuration:
                                            _vm.animationDuration,
                                        },
                                        attrs: { color: "red", size: "6" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-col", { staticClass: "text-right" }),
                        ],
                        1
                      ),
                      _c("v-slider", {
                        attrs: {
                          "always-dirty": "",
                          color: "#005db5",
                          max: "1024",
                          min: "0",
                          "track-color": "grey",
                        },
                        on: { mouseup: _vm.post_duration },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.decrement(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" remove ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: { color: "#005db5" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.increment(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" add ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.pos[2],
                          callback: function ($$v) {
                            _vm.$set(_vm.pos, 2, $$v)
                          },
                          expression: "pos[2]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }